




import Vue from 'vue';
import Quagga from 'quagga';
import { IQuaggaOnDecode } from './types';

export default Vue.extend({
  name: 'Scanner',
  props: {
    width: {
      type: Number,
    },
  },
  async mounted() {
    this.initScanner();
  },
  methods: {
    start() {
      Quagga.start();
      Quagga.onDetected(this.detected);
      console.log('Quagga started');
    },

    detected(data: IQuaggaOnDecode) {
      if (data) {
        const {
          codeResult: { code },
        } = data;
        this.$emit('founded', this.getCode(code));
        this.$emit('close');
        Quagga.stop();
      }
    },

    getCode(scannedCode: string) {
      if (!scannedCode.includes('/')) {
        return scannedCode;
      }
      const indexLastSlash = scannedCode.lastIndexOf('/');
      return scannedCode.slice(indexLastSlash + 1);
    },

    async initScanner() {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: this.$refs.quagga,
            constraints: {
              width: this.width,
              height: 300,
              facingMode: 'environment',
            },
          },
          debug: true,
          locator: {
            halfSample: true,
            patchSize: 'x-large',
          },

          decoder: {
            readers: [
              'code_128_reader',
              'ean_8_reader',
              'code_39_reader',
              'code_39_vin_reader',
              'codabar_reader',
              'upc_reader',
              'upc_e_reader',
              'i2of5_reader',
              '2of5_reader',
              'code_93_reader',
              'ean_reader',
            ],
          },
        },
        () => this.start(),
      );
    },
  },
});
